



























import { Observer } from 'mobx-vue'
import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { InviteViewModel } from '@/modules/profile/activity/viewmodels/invites-viewmodel'

@Observer
@Component({
  components: {
    invite: () => import('@/modules/profile/activity/pages/components/invite/invite.vue'),
    'invite-other': () => import('@/modules/profile/activity/pages/components/invite/invite-other.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new InviteViewModel()
  tabs = null

  @Watch('$route', { immediate: true }) tabChange(val) {
    const hash = this.$router.currentRoute.hash
    if (hash) {
      if (hash === '#invite') {
        setTimeout(() => {
          document.getElementById(`list-invite`)?.scrollIntoView({ behavior: 'smooth' })
        }, 300)
      }
    }
  }
}
