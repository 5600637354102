import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { apiService } from '@/services/api-services'
import { computed, observable } from 'mobx'
import { asyncAction } from 'mobx-utils'

export class InviteViewModel {
  @observable invites: any[] = []

  constructor() {
    this.fetchInvites()
    //
  }

  @asyncAction *fetchInvites() {
    try {
      const res = yield apiService.daos.getInvites({ _start: 0, _limit: -1 })
      if (res) {
        this.invites = res.data
      }
    } catch (error) {
      snackController.commonError(error)
    }
  }

  @asyncAction *acceptInvite(item: any) {
    try {
      item.accepting = true
      const res = yield apiService.daos.acceptInvite({ inviteId: item.id })
      if (res) {
        snackController.success(`You have accepted join Dao ${item.dao.name}`)
        item.status = 'accepted'
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.accepting = false
    }
  }

  @asyncAction *rejectInvite(item: any) {
    try {
      item.rejecting = true
      const res = yield apiService.daos.rejectInvite({ inviteId: item.id })
      if (res) {
        snackController.success(`You have rejected join Dao ${item.dao.name}`)
        item.status = 'rejected'
      }
    } catch (error) {
      snackController.commonError(error)
    } finally {
      item.rejecting = false
    }
  }

  @computed get totalInvite() {
    return this.invites.length
  }

  @computed get invitations() {
    return this.invites.filter(item => item.status === 'pending')
  }

  @computed get history() {
    return this.invites.filter(item => item.status !== 'pending')
  }
}
